/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
// import { BrowserRouter as Router } from 'react-router-dom'
import axios from 'axios'
import * as Yup from 'yup'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import './index.css'
const API_END_POINT = process.env.REACT_APP_API_END_POINT

export default class ImpactCelebration extends Component {
  public state = {
    hasError: false,
    token: '',
    amount: 100,
    loading: true
  }

  /* Get query string params */
  public getQueryStringValue = (key: string) => {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          '^(?:.*[&\\?]' +
          encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
          '(?:\\=([^&]*))?)?.*$',
          'i'
        ),
        '$1'
      )
    )
  }

  submit = async (values: any) => {
    const { token, amount } = this.state
    // this.setState({amount: amount * values.qty})
    // values.phoneNumber = values.email
    this.setState({
      loading: true,
      amount: amount * values.qty
    }, async () => {
      const requestObj = {
        token: token === '' ? 'OPENENDED' : token,
        amount: this.state.amount,
        values
      }
      console.log('requestObj >>>>> ', requestObj)
      const { data: responseBody } = await axios.post(`${API_END_POINT}/music/save-donation-info`, requestObj)
      const response = responseBody ? responseBody : null
      this.setState({ loading: false })
      if (response) {
        const { history }: any = this.props
        const donationId = response.id
        history.push(`/mcm-payments?token=${response.token}&donationId=${donationId}&amount=${amount}`)
      } else {
        this.setState({ hasError: true })
      }
    })
  }

  componentDidMount() {
    const token = this.getQueryStringValue('token')
    // const amount = this.getQueryStringValue('amount')
    if (token) {
      this.setState({
        token
      }, async () => {
        const { data: responseBody } = await axios.get(`${API_END_POINT}/music/extract-token?token=${token}`)
        const response = responseBody ? responseBody : null
        this.setState({ loading: false, amount: response.amount })
        if (!response) {
          this.setState({ hasError: true })
        }
      })
    } else {
      this.setState({ amount: 100, loading: false })
    }
  }

  render() {
    const { hasError, loading, amount } = this.state
    // const token = this.getQueryStringValue('token')
    // const amount = this.getQueryStringValue('amount')

    let initialValues: any = {
      organizationName: '',
      contactName: '',
      email: '',
      phoneNumber: '',
      pmcContact: '',
      qty: 1
    }
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    let validationSchema = Yup.object({
      qty: Yup.number().min(1, "Min 1 Qty. is required").required(
        'Quantity is required'
      ),
      contactName: Yup.string().required(
        'Contact Name is required'
      ),
      email: Yup.string()
        .required('email required')
        .email('Enter valid email'),
      phoneNumber: Yup.string()
        .required('Cell Phone Number is required')
        .matches(phoneRegExp, 'Cell Phone number is not valid')
        .min(10, "too short")
        .max(10, "too long"),
    })

    /* if (token !== '') {
      initialValues = {
        qty: 1,
        organizationName: '',
        contactName: '',
        email: '',
        phoneNumber: '',
        players: [{
          name: '',
          email: '',
          instaHandle: ''
        }],
        pmcContact: ''
      }
    } */
    return (
      <section className="music__wrapper">
        <header className="header">
          <img src="images/pmc-primary-logo.png" className="logo" />
        </header>
        <main>
          <div className="music__inner">
            {
              (hasError || loading) ? <h2 className="wait__title">{loading ? 'Please wait...!' : 'Something went wrong!'}</h2>
                :
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    await this.submit(values)
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <div className="add-form-list pd-tp-0">
                        <h6 className="title">Amount: ${amount * values.qty / 100}</h6>
                        <div className="items">
                          <div>Quantity of tickets</div>
                          <label htmlFor={`qty`}>Quantity of tickets</label>
                          <Field
                            name={`qty`}
                            placeholder="Quantity of tickets"
                            type="number"
                            // onChange={() => this.setState({amount: amount * values.qty})}
                            className="form-control"
                          />
                          <ErrorMessage
                            name={`qty`}
                            component="div"
                            className="field-error"
                          />
                        </div>
                        <h3 className="title">Your Info</h3>


                        <div className="items">
                          <label htmlFor={`contactName`}>Contact Name</label>
                          <Field
                            name={`contactName`}
                            placeholder="Contact Name"
                            type="text"
                            className="form-control"
                          />
                          <ErrorMessage
                            name={`contactName`}
                            component="div"
                            className="field-error"
                          />
                        </div>
                        <div className="items">
                          <label htmlFor={`email`}>Email</label>
                          <Field
                            name={`email`}
                            placeholder="Email"
                            type="text"
                            className="form-control"
                          />
                          <ErrorMessage
                            name={`email`}
                            component="div"
                            className="field-error"
                          />
                        </div>
                        <div className="items">
                          <label htmlFor={`phoneNumber`}>Cell Phone Number</label>
                          <Field
                            name={`phoneNumber`}
                            placeholder="Cell Phone Number"
                            type="text"
                            className="form-control"
                          />
                          <ErrorMessage
                            name={`phoneNumber`}
                            component="div"
                            className="field-error"
                          />
                        </div>
                      </div>

                      <button type="submit" className="btn next-btn">Next</button>
                    </Form>
                  )}
                </Formik>
            }
          </div>
        </main>
        <footer className="footer">
          <div className="footer-inner">
            <p>
              {/* www.musiccitymasters.com */}
              www.pmccaresgolfclassic.com
            </p>
          </div>
        </footer>
      </section>
    )
  }
}
