import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ImpactCelebration from "./views/musicCityMaster/impactCelebration";

const Suspense = (React as any).Suspense;
const lazy = (React as any).lazy;

const LicensePlate = lazy(() => import("./views/licencePlate"));
const Links = lazy(() => import("./views/links"));
const Payments = lazy(() => import("./views/payments"));
const Invoice = lazy(() => import("./views/invoice"));
const Success = lazy(() => import("./views/payments/success"));
const Error = lazy(() => import("./views/payments/error"));
const MusicCityMaster = lazy(() => import("./views/musicCityMaster"));
const MCMPayments = lazy(() => import("./views/musicCityMaster/payment"));
const MCMInvoice = lazy(() => import("./views/musicCityMaster/invoice"));
const Donation = lazy(() => import("./views/musicCityMaster/donation"));
const LicensePlateMonthly = lazy(() => import("./views/licencePlate/monthly"));
const PaymentsSubscriptionMonthly = lazy(
  () => import("./views/payments/monthly")
);
const SubscriptionInvoice = lazy(() => import("./views/invoice/monthly"));
const Shortly = lazy(() => import("./views/shortly/index"));
const Events = lazy(() => import("./views/events"));
const EventsSuccess = lazy(() => import("./views/events/success"));
const Registration = lazy(() => import("./views/events/registration"));
const Auction = lazy(() => import("./views/events/auction"));
const EventPayments = lazy(() => import("./views/events/payment"));
const RandomItems = lazy(() => import("./views/events/randomItems"));
const LicensePlateFreeParking = lazy(
  () => import("./views/licencePlate/freeParking")
);
const OptOut = lazy(() => import("./views/links/opt-out"));
// const EventLicenseplate = lazy(() => import('./views/eventChatBot/licenseplate'))
const Login = lazy(() => import("./views/subscriptionDashboard/login"));
const Dashboard = lazy(() => import("./views/subscriptionDashboard"));
const OCP_licenseplate = lazy(() => import("./views/ocp"));
const OCP_Payment = lazy(() => import("./views/ocp/payment"));
const OCP_Invoice = lazy(() => import("./views/ocp/invoice"));
const OCP_Login = lazy(() => import("./views/ocp/login"));
const OCP_Dashboard = lazy(() => import("./views/ocp/dashboard"));
const SAMALicensePlate = lazy(() => import("./views/sama/licensePlate"));
const PMCLicensePlate = lazy(() => import("./views/pmc/licensePlate"));
const PMCPayments = lazy(() => import("./views/pmc/payment"));
const PMCSuccess = lazy(() => import("./views/pmc/success"));
const PMCCancel = lazy(() => import("./views/pmc/cancel"));
const FtMyersPayments = lazy(() => import("./views/ftMyers"));

const StripePayment = lazy(() => import("./views/stripe/index"))
const StripeError = lazy(() => import("./views/stripe/error"))
const StripeSuccess = lazy(() => import("./views/stripe/success"))
const LicensePlateParking = lazy(() => import("./views/parking/licensePlate"))
const ParkingPayments = lazy(() => import("./views/parking/payment"))
const StripePaymentNew = lazy(() => import("./views/stripe/payment-new"))
const ExtensionParking = lazy(() => import("./views/parking/extension"))
const New_Invoice = lazy(() => import("./views/newInvoice"))
const NewShortly = lazy(() => import("./views/shortly/receipt-url"))
const consolidated = lazy(
  () => import("./subscriptionConsolidatedInvoice/index")
)
const Consolidated_Invoice = lazy(
  () => import("./subscriptionConsolidatedInvoice/subscriptionsConsolidated")
)
const FileUpload = lazy(() => import("./views/FileUpload/index"));
export default class Routing extends Component {
  render() {
    return (
      <Suspense fallback={<div />}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/token/:id" component={Links} />
            <Route path="/sama/lp" component={SAMALicensePlate} />
            <Route path="/pmc/lp" component={PMCLicensePlate} />
            <Route path="/pmc/payments" component={PMCPayments} />
            <Route path="/pmc/success" component={PMCSuccess} />
            <Route path="/pmc/cancel" component={PMCCancel} />
            <Route path="/lp" component={LicensePlate} />
            <Route path="/lp-free" component={LicensePlateFreeParking} />
            <Route path="/lpm/:id" component={LicensePlateMonthly} />
            <Route path="/payments" component={Payments} />
            <Route path="/s" component={PaymentsSubscriptionMonthly} />
            <Route path="/success" component={Success} />
            <Route path="/cancel" component={Error} />
            <Route path="/invoice" component={Invoice} />
            <Route path="/invs" component={SubscriptionInvoice} />
            <Route path="/opt-out/:id" component={OptOut} />
            <Route path="/sponsor" component={MusicCityMaster} />
            <Route path="/impact-celebration" component={ImpactCelebration} />
            <Route path="/mcm-payments" component={MCMPayments} />
            <Route path="/inv" component={MCMInvoice} />
            <Route path="/donation" component={Donation} />
            <Route path="/l/:id" component={LicensePlateParking} />
            <Route path="/e/:id" component={ExtensionParking} />
            <Route path="/parking-payment" component={ParkingPayments} />
            <Route path="/parking-payment-s/:id" component={StripePaymentNew} />
            <Route
              path="/ocp/lp"
              {...this.props}
              component={OCP_licenseplate}
            />
            <Route
              path="/pmc/file-upload"
              {...this.props}
              component={FileUpload}
            />
            <Route
              path="/ocp/payment"
              {...this.props}
              component={OCP_Payment}
            />
            <Route
              path="/ocp/invoice"
              {...this.props}
              component={OCP_Invoice}
            />
             <Route
              path="/ocp/login"
              component={OCP_Login}
            />
              <Route
              path="/ocp/dashboard"
              component={OCP_Dashboard}
            />
            <Route
              path="/ftmyers/payment"
              {...this.props}
              component={FtMyersPayments}
            />
            <Route path="/New-Invoice" component={New_Invoice} />
            <Route
              path="/paymentpage-new"
              {...this.props}
              component={StripePayment}
            />
            <Route
              path="/payment-error"
              {...this.props}
              component={StripeError}
            />
            <Route
              path="/payment-success"
              {...this.props}
              component={StripeSuccess}
            />
            <Route
              path="/events"
              render={({ match: { url } }) => (
                <>
                  <header className="header">
                    <img
                      alt="PMC"
                      src="https://payment.parkingmgt.com/images/pmc_care.png"
                      className="logo"
                    />
                  </header>
                  <Route path={`${url}/`} component={Events} exact />
                  <Route
                    path={`${url}/registration`}
                    component={Registration}
                  />
                  <Route path={`${url}/auction`} component={Auction} />
                  <Route path={`${url}/random-items`} component={RandomItems} />
                  <Route path={`${url}/payment`} component={EventPayments} />
                  <Route path={`${url}/success`} component={EventsSuccess} />
                  <footer className="footer">
                    <div className="footer-inner">
                      <p>
                        <a href="www.pmccaresgolfclassic.com">
                          {/* www.musiccitymasters.com */}
                           www.pmccaresgolfclassic.com
                        </a>
                      </p>
                    </div>
                  </footer>
                </>
              )}
            />
            {/* <Route
            path="/eventchatbot"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/lp`} component={EventLicenseplate} />
              </>
            )}
          /> */}
            <Route path="/z/:id" component={Shortly} />
            <Route path="/r/:id" component={NewShortly} />
            <Route path="/login" component={Login} />
            <Route
              path="/dashboard"
              render={({ match: { url } }) => (
                <>
                  <header className="header">
                    <img src="images/logo.webp" className="logo" alt="" />
                  </header>
                  <Route path={`${url}/`} component={Dashboard} />
                  <footer className="footer">
                    <div className="footer-inner">
                      <p>
                        <a href="http://parkingmgt.com/">
                          Parking Management Company
                        </a>
                      </p>
                    </div>
                  </footer>
                </>
              )}
            />
            <Route path="/consolidated/:id" component={consolidated} />
            <Route
              path="/consolidated-Invoice"
              component={Consolidated_Invoice}
            />
          </Switch>
        </BrowserRouter>
      </Suspense>
    );
  }
}
