import './App.css'
// import Routing from './views/nav/routing'
import Routing from './Routings'

function App() {
  return (
    <Routing/>
  )
}

export default App
